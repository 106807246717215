<template>

  <v-card class="elevation-0">

    <v-card-title>
      {{ title }}
      <v-spacer></v-spacer>
    </v-card-title>

    <v-expand-transition>
     <v-card class="mx-2 mb-1 elevation-0 color-top-left-gradient-3" v-show="showFilter===true">
      <v-card-text>

        <v-row>
          <v-col cols="12" sm="3" md="3">
            <v-menu
                v-model="menuDate1"
                :close-on-content-click="false"
                max-width="290"
            >
              <template v-slot:activator="{ on, attrs } ">
                <v-text-field
                    :value="computedDateFormattedDatefns(filterTanggal)"
                    prepend-inner-icon="mdi-calendar"
                    clearable
                    label="Tanggal"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    @click:clear="filterTanggal = null"
                    dense
                ></v-text-field>
              </template>

              <v-date-picker
                  v-model="filterTanggal"
                  @change="menuDate1 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
          <v-col
              cols="12"
              sm="3"
              md="3"
          >
            <v-autocomplete
                v-model="filterFwarehouses"
                :items="itemsFWarehouseActive"
                item-value="id"
                item-text="description"
                auto-select-first
                dense
                chips
                small-chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Warehouse"
                persistent-hint
                multiple
                hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                    v-bind="data.attrs"
                    :input-value="data.selected"
                    close
                    @click="data.select"
                    @click:close="removeAutoCompleteFWarehouse(data.item)"
                >
                  <v-avatar left>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-avatar>
                  <span class="font-weight-regular caption"> {{ data.item.kode1 }} </span>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>{{data.item.description}}</v-list-item-title>
                    <v-list-item-subtitle>{{data.item.kode1}}</v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>

          </v-col>

          <v-col
            cols="12"
            sm="4"
            md="4"
          >
            <v-autocomplete
                autofocus
                v-model="filterFmaterials"
                :items="itemsFMaterial"
                item-value="id"
                item-text="pcode"
                auto-select-first
                dense
                chips
                small-chips
                deletable-chips
                color="blue-grey lighten-2"
                label="Product"
                persistent-hint
                multiple
                hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeAutoCompleteFMaterial(data.item)"
                >
                  <v-avatar left>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-avatar>
                  <span class="font-weight-bold caption ml-1 blue--text"> {{ data.item.pcode }} </span>
                </v-chip>
              </template>

              <template v-slot:item="data">
                <template v-if="typeof data.item !== 'object'">
                  <v-list-item-content v-text="data.item"></v-list-item-content>
                </template>
                <template v-else>
                  <v-list-item-avatar>
                    <v-img :lazy-src="lookupImageUrlLazy(data.item)" :src="lookupImageUrl(data.item)"></v-img>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title>
                      <span class="blue--text">{{data.item.pcode}}</span>
                    </v-list-item-title>
                    <v-list-item-subtitle>
                      <span class="caption">
                        {{data.item.pname}}
                      </span>
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </template>
            </v-autocomplete>
          </v-col>
          <v-col cols="6" sm="2" md="2">
            <v-btn color="primary" small @click="runExtendedFilter">
              Terapkan
              <v-icon small class="ml-1" color="green lighten-2">mdi-filter</v-icon>
            </v-btn>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>
    </v-expand-transition>
    <div>
      <v-row>
        <v-col cols="4" sm="6" md="6">
        </v-col>
        <v-col cols="8" sm="6" md="6">
          <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              hide-details
              class="mx-2 mb-2"
          ></v-text-field>
        </v-col>
      </v-row>

      <v-data-table
          :headers="headers"
          :items="computedItemsFstock"
          :search="search"
          :page.sync="currentPage"
          dense
      >
        <template v-slot:[`item.number`]="{ index }">
          {{ (index + ((currentPage-1)*pageSize) +1 )}}
        </template>

        <template v-slot:[`item.fwarehouseBean`]="{ item }">
          <div class="fill-height ">
            {{ lookupFWarehouse(item.fwarehouseBean).kode1 }}
          </div>
        </template>

      </v-data-table>

    </div>

  </v-card>

</template>

<script>
import FileService from "../../../services/apiservices/file-service"

import FtSaleshFilter from "../../../models/payload/ft-salesh-filter";

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import { dateFormattedDatefnsWithTime,  dateFormattedDatefns, dateFormattedDatefnsShort, dateFormattedDatefnsWithTimeShort} from "@/utils/date-utils"
// import {parseISO} from "date-fns";
// import moment from 'moment'
import {format, parseISO} from "date-fns";
import FStockService from "@/services/apiservices/f-stock-service";
import KonversiProductHelper from "@/services/konversi-product-helper";

export default {
  name: 'FStockTable',
  components: {
  },
  props:{
    newsletterPopup: Boolean
  },
  data () {
    return {
      title: 'SALDO STOCK',

      // date: format(parseISO(new Date().toISOString()), 'dd-MMM-yyyy'),
      menuDate1: false,
      filterTanggal: format(parseISO(new Date().toISOString()), 'yyyy-MM-dd'),
      // filterTanggal: '',

      showFilter: true,
      filterFmaterials: [],
      filterFwarehouses: [],


      currentPage: 1,
      totalTablePages: 1,
      totalPaginationPages: 1,
      pageSize: 10,
      pageSizes: [10, 20, 40, 75, 150, 500, 1000, 2500, 5000],

      search: '',
      headers: [
        {
          text: 'No',
          value: 'number',
          width: '8%',
          sortable: false
        },
        { text: 'Warehouse', value: 'fwarehouseKode1', width: '10%' },
        { text: 'Produk', value: 'fmaterialPcode', width: '10%' },
        { text: 'Stok', value: 'saldoStock', width: '15%'  },
        { text: 'Stok In Pcs', value: 'saldoAkhir', width: '10%'  }
      ],

      // formMode: '',
      itemSelectedIndex: -1,
      itemSelected: '',
      itemsFstock:[],


      boldClass: 'font-weight-bold',
      redClass: 'red--text'
    }
  },
  watch: {
    multiSelect:function (value){
      if (value==false){
        this.selectedItems = []
      }
    },
    filterTanggal:{
      handler: function (value){
        console.log(value)
      }
    }


  },

  computed: {
    currentUser(){
      return this.$store.state.auth.user;
    },

    haveRoleAdminMutlak(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleAdmin(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (
            this.currentUser.roles.includes("ROLE_ADMIN") ||
            this.currentUser.roles.includes("ROLE_ADMIN1") ||
            this.currentUser.roles.includes("ROLE_ADMIN2")
        ){
          isValid=true
        }
      }
      return isValid
    },
    haveRoleInventory(){
      let isValid = false
      if (this.currentUser.roles != null){
        if (this.currentUser.roles.includes("ROLE_INVENTORY") ){
          isValid=true
        }
      }
      return isValid
    },

    itemsFDivision(){
      return this.$store.state.data.listFDivision
    },
    itemsFMaterial(){
      return this.$store.state.data.listFMaterial
    },
    itemsFWarehouse(){
      return this.$store.state.data.listFWarehouse
    },
    itemsFWarehouseActive(){
      return this.$store.state.data.listFWarehouse.filter(x => x.statusActive===true)
    },
    computedItemsFstock(){
      return this.itemsFstock.map( x => {
        let fstockBean = x

        fstockBean.fwarehouseKode1 =  this.lookupFWarehouse(x.fwarehouseBean).kode1

        let fmaterial = this.lookupFMaterial(x.fmaterialBean)
        fstockBean.fmaterialPcode =  fmaterial.pcode

        fstockBean.saldoStock = this.lookupUom1234StringUom(fmaterial, x.saldoAkhir)


        return fstockBean
      }).sort((a, b) => (a.fwarehouseBean > b.fwarehouseBean) ? 1: -1)
    }

  },

  methods: {


    lookupUom1234StringUom(fmaterial, pcsOrUom4){
      return KonversiProductHelper.getUom1234StringUom(fmaterial, pcsOrUom4)
    },

    formatDate (date) {
      if (!date) return null
      const [year, month, day] = date.split('-')
      return `${month}/${day}/${year}`
    },
    computedDateFormattedDatefns (value) {
      return value ? format(parseISO(value), 'dd-MMM-yyyy') : ''
    },

    runExtendedFilter(){

      const ftSaleshFilter = new FtSaleshFilter()

      ftSaleshFilter.fdivisionIds =  this.filterFdivisions //dilakukan pada back-end untuk security

      if (this.filterFmaterials.length >0){
        ftSaleshFilter.fmaterialIds =  this.filterFmaterials
      }else {
        ftSaleshFilter.fmaterialIds = this.itemsFMaterial.map( item => item.id)
      }
      if (this.filterFwarehouses.length >0){
        ftSaleshFilter.fwarehouseIds =  this.filterFwarehouses
      }else {
        ftSaleshFilter.fwarehouseIds = this.itemsFWarehouse.map( item => item.id)
      }

      ftSaleshFilter.invoiceDateFrom =  this.filterTanggal
      ftSaleshFilter.invoiceDateTo =  this.filterTanggal

      // console.log(`>>>> ${JSON.stringify(ftSaleshFilter.fsalesmanIds)}`)
      // console.log(JSON.stringify(ftSaleshFilter))
      FStockService.getAllByFmaterialBeanInAndFwarehouseBeanInAndStockDate(ftSaleshFilter).then(
          response =>{
            // const { items, totalPages} = response.data
            // this.ftSaleshs = items
            // this.totalPaginationPages = totalPages

            this.itemsFstock = response.data
            // console.log(JSON.stringify(response.data))

          },
          error =>{
            console.log(error)
          }
      )
    },

    fetchParent(){
      /**
       * Pada suatu saat akan tidak mungkin untuk load semua Fcustomer
       */
      this.$store.dispatch('data/loadFMaterial')
      this.$store.dispatch('data/loadFWarehouse')

    },

    linkNeated(link){
      return link.replace(/\s+/g, '-').toLocaleLowerCase()
    },

    getColorStatusActive (trueFalse) {
      if (trueFalse === true) return 'green'
      else if (trueFalse === false) return 'gray'
      else return 'gray'
    },

    removeAutoCompleteFWarehouse (item) {
      const index = this.filterFwarehouses.indexOf(item.id)
      if (index >= 0) this.filterFwarehouses.splice(index, 1)
    },

    removeAutoCompleteFMaterial (item) {
      const index = this.filterFmaterials.indexOf(item.id)
      if (index >= 0) this.filterFmaterials.splice(index, 1)
    },
    lookupFDivision (fdivisionBean) {
      const str = this.itemsFDivision.filter(x => x.id===fdivisionBean)
      if (str.length>0){
        return str[0]
      }else {
        return undefined
      }
    },
    
    lookupFWarehouse (fwarehouseBean) {
      const str = this.itemsFWarehouse.filter(x => x.id===fwarehouseBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupFMaterialPcode (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return `${str[0].pcode}`
      }else {
        return '-'
      }
    },
    lookupFMaterial (fmaterialBean) {
      const str = this.itemsFMaterial.filter(x => x.id===fmaterialBean)
      if (str.length>0){
        return str[0]
      }else {
        return '-'
      }
    },

    lookupImageUrl(item){
      if (item.avatarImage===undefined || item.avatarImage===""){
        return './assets/images/no_image_available.jpeg'
      }else {
        return FileService.image_url_verylow(item.avatarImage)
      }
    },
    lookupImageUrlLazy(){
      return './assets/images/no_image_available.jpeg'
    },
    formattedCurrencyValue(prefix, value){
      if(! value){ return "0"}
      return prefix + "" + parseFloat(value).toLocaleString()
    },

    dateFormattedDatefns(value){
      return dateFormattedDatefns(value)
    },
    dateFormattedDatefnsShort(value){
      return dateFormattedDatefnsShort(value)
    },
    dateFormattedDatefnsWithTime(value){
      return dateFormattedDatefnsWithTime(value)
    },
    dateFormattedDatefnsWithTimeShort(value){
      return dateFormattedDatefnsWithTimeShort(value)
    },

    computedDateFormattedDatefnsWithTime(value) {
      let strDate = ''
      try{
        strDate = value ? format(parseISO(value), 'yyyy-MM-dd HH:mm') : ''
      }catch (e) {
        e.toString()
      }
      return strDate
    },

  },

  mounted() {

    if (!this.currentUser) {
      this.$router.push('/login')
    }else {
      this.fetchParent()
    }

  }

}
</script>

<style scoped>
 .single-line-height{
   line-height: 0.9em;
 }
 .short-line-height {
   line-height: 0.5em;
 }


</style>

<template>
  <div>
    <FStockTable
    ></FStockTable>

  </div>
</template>

<script>
import FStockTable from "@/components/inventory/stock/FStockTable";
export default {
  name: "StockView",
  components: { FStockTable },
  data() {
    return {
      firstTabTitle: 'WAREHOUSE',
      firstTabIcon: 'mdi-home-variant'
    }
  },
  methods: {
  }
}
</script>

<style scoped>
</style>